body.rtl {
  direction: rtl;

  .status {
    padding-left: 10px;
    padding-right: 68px;
  }

  .table th,
  .table td {
    text-align: right;
  }

  @media screen and (min-width: 631px) {
    .column {
      padding-left: 5px;
      padding-right: 5px;

      &:first-child {
        padding-left: 5px;
        padding-right: 10px;
      }
    }
  }
}
